ion-content {
  --ion-background-color: #f9f5f1;
}

ion-menu {
  --ion-background-color: #012834;
}

ion-row {
  --ion-background-color: #f9f5f1;
}

.alert-wrapper {
  width: 300px !important;
}

@font-face {
  font-family: "SourceSansProBold";
  src: local("Source Sane Pro"),
    url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf)
      format("truetype");
}


@font-face {
  font-family: "SourceSansProRegular";
  src: local("Source Sane Pro"),
    url(./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf)
      format("truetype");
}

.custom-font-size .multiselect-dropdown .dropdown-multiselect__caret:before {
  right: 35px !important;
}
/*Changes for US675871 --Start */

.input-group .md-drppicker {
  width: 170%;
  top: 45px !important;
}

.input-group-datepicker .md-drppicker {
  width: auto;
  top: 45px !important;
}

.md-drppicker.double {
  width: 36em !important;
}
.md-drppicker td.active,
.md-drppicker td.active:hover {
  background: #4bcd3e !important;
  border-radius: 11.0442px !important;
  border-color: transparent;
  color: #012834 !important;
  font-family: "Source Sans Pro" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

.md-drppicker th {
  color: #4e6971 !important ;
  font-size: 12px !important;

  font-family: "SourceSansProRegular" !important;
  font-style: normal !important;
  font-weight: normal !important;
  text-align: center !important;
}

.md-drppicker option.disabled,
.md-drppicker td.disabled {
  color: #b3bfc2 !important;
  cursor: not-allowed;
  text-decoration: none !important;
}

.e-tooltip {
  font-family: "SourceSansProRegular";
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #4e6971;
  margin-left: 12px;
}

.md-drppicker .dropdowns select.monthselect option:disabled,
.md-drppicker .dropdowns select.yearselect option:disabled {
  color: #b3bfc2 !important;
  cursor: not-allowed;
  text-decoration: none !important;
}
.md-drppicker .dropdowns select.monthselect,
.md-drppicker .dropdowns select.yearselect {
  font-family: "SourceSansProRegular" !important;
  text-decoration: none !important;
  font-size: 14px !important;
  color: #012834 !important;
}
.md-drppicker .calendar {
  max-width: 300px !important;
}

/*Changes for US675839 --end */
.highlightSearchText {
  background-color: rgb(75 205 62 / 70%);
  color: black;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 2.3 !important;
  padding: 0 0px !important;
}

.mat-toggle-button-group-custom {
  border-radius: 50px;
  height: 44px;
  width: 100%;
  border-radius: 11.8125rem;
  padding: 0.1875rem 0.1875rem;
  background-color: #f9f5f1;
  border-color: transparent;
}
.mat-toggle-button-custom {
  font-family: SourceSansProBold;
  font-size: 14px;
  height: 36px;
  width: 90px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.rotating-stop {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
}
.dataTables_length{
  font-family:SourceSansProBold; 
  font-size: 12px;
  margin-top: 1%;
}

.dataTables_filter{
  font-family:SourceSansProBold; 
  font-size: 12px;
}

.dataTables_info{
  font-family:SourceSansProBold; 
  font-size: 12px;
  clear: none !important;
  float: none !important;
  padding-top: 0 !important;
  margin-top: 1.5%;
}

.paginate_button{
  font-family:SourceSansProBold; 
  font-size: 12px;
  display: contents !important;
}

.dataTables_paginate .paging_full{
  float: none !important;
  text-align: left !important;
  padding-top: 0 !important;
  margin-top: -1.7%;
  margin-left: 280px;
}

.paging_full{
  float: none !important;
  text-align: left !important;
  padding-top: 0 !important;
  margin-top: -1.7%;
  margin-left: 280px;
}

.dataTables_scroll
{
    overflow:auto;
}

.dataTables_empty{
    width: 35%;
    padding: 20px !important;
    background-color: #e6e6e6;
    font-family: 'SourceSansProRegular';
    font-size: 14px;
}

table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:after {
  opacity: 1 !important;
}

table.dataTable thead > tr > th.sorting:before, table.dataTable thead > tr > th.sorting:after, table.dataTable thead > tr > th.sorting_asc:before, table.dataTable thead > tr > th.sorting_asc:after, table.dataTable thead > tr > th.sorting_desc:before, table.dataTable thead > tr > th.sorting_desc:after, table.dataTable thead > tr > th.sorting_asc_disabled:before, table.dataTable thead > tr > th.sorting_asc_disabled:after, table.dataTable thead > tr > th.sorting_desc_disabled:before, table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  line-height: 10px !important;
  font-size: 10px !important;

  padding-left: 0px !important;
  padding-right: initial !important;

  left: 0px !important;
  right: auto !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
